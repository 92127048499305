.App {
  text-align: center;
}
.app-suspense {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}
.app-suspense-img {
  height: 80px;
  width: 80px;
}
.app-suspense-img {
  animation: breathing 2s ease-in-out infinite;
}
@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@font-face {
  font-display: swap;
  font-family: ABCMaxiRoundRegular;
  src:
    url(../public/ABCMaxiPlus/ABCMaxiRound-Regular.woff2) format("woff2"),
    url(../public/ABCMaxiPlus/ABCMaxiRound-Regular.woff) format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400&display=swap");
